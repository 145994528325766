import React, { useCallback, useEffect, useState } from "react";
import "./variant-select.scss";
import { ProductOptions, VariantEntity, VariantOption } from "../../models/data/product";
export default function VariantSelect(props: { productOptions: ProductOptions[], variants: VariantEntity[], selectedVariant: VariantEntity, variantChange: (v: VariantEntity) => void }) {

  const [selectedOptions, setSelectedOptions] = useState<VariantOption[]>(props.selectedVariant.options)
  const [productOptions] = useState<ProductOptions[]>(props.productOptions);
  const [variants] = useState<VariantEntity[]>(props.variants);
  const [selectedVariant, setSelectedVariant] = useState<VariantEntity>(props.selectedVariant);

  const isSelected = useCallback((categoryHandle: string, optionHandle: string) => {
    var optionCategory = selectedOptions.find(svo => svo.optionHandle === categoryHandle);
    if (optionCategory === undefined) return 'd-none';
    if (optionCategory.handle === optionHandle) return 'bg-success text-dark border-secondary';
    return 'btn btn-light border-dark';
  }, [selectedOptions]);

  // NOT WORKING WITH MULTIPLE OPTIONS
  const isAvailable = useCallback((categoryHandle: string, optionHandle: string) => {
    var res = false;
    const domOption = productOptions[0];
    const selectedDomOption = selectedOptions.find(so => so.optionHandle === domOption.optionHandle);
    if (selectedDomOption === undefined) return false;
    if (domOption.optionHandle === categoryHandle && productOptions.length > 1) return true;
    variants.forEach((v: VariantEntity, i: number) => {
      if (res) return;
      var indexOfOption = v.options.findIndex(vo => vo.optionHandle === categoryHandle && vo.handle === optionHandle);
      if (productOptions.length > 1)
        res = indexOfOption > -1 && v.options.findIndex(vo => vo.optionHandle === selectedDomOption.optionHandle && vo.handle === selectedDomOption.handle) > -1;
      else
      res = indexOfOption > -1;
    });

    return res;
  }, [productOptions, selectedOptions, variants])

  useEffect(() => {
    setSelectedOptions(selectedVariant.options);
    props.variantChange(selectedVariant);
  }, [props, selectedVariant])

  const selectOption = useCallback((categoryHandle: string, handle: string) => {
    variants.forEach((v: VariantEntity, i: number) => {
      var newFilter = v.options.findIndex((vo) => {
        return vo.optionHandle === categoryHandle && vo.handle === handle
      });
      if (newFilter === -1) {
        return;
      }
      var otherOptions = selectedOptions.filter(so => so.optionHandle !== categoryHandle);
      var matches = 0;
      otherOptions.forEach((oo) => {
        if (productOptions.findIndex(po => po.optionHandle === oo.optionHandle) === -1) matches++;
        else {
          var another = v.options.findIndex(vo => vo.optionHandle === oo.optionHandle && vo.handle === oo.handle);
          if (another > -1) matches++;
        }
      });
      if (matches === otherOptions.length)
        setSelectedVariant(v);
    });
  }, [variants, selectedOptions, productOptions]);

  return <div>
    {productOptions.map((po, i) => {
      return <div key={i} className="mb-3">
        <h5>{po.optionName}</h5>
        <div className="d-flex align-items-center justify-content-start gap-3 flex-wrap">
          {po.options.map((o, i2) => {
            const available = isAvailable(po.optionHandle, o.handle);
            return <button key={i2} disabled={!available} onClick={() => selectOption(po.optionHandle, o.handle)} className={`p-2 border rounded ${available ? 'text-decoration-none' : 'text-decoration-line-through opacity-2'} ${isSelected(po.optionHandle, o.handle)}`}>{o.viewName}</button>;
          })}
        </div>
      </div>
    })}
  </div>;
}



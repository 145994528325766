import React, { useCallback, useEffect, useState } from "react";
import "./product-page.scss";
import { useNavigate, useParams } from "react-router-dom";
import ProductEntity, { VariantEntity } from "../../models/data/product";
import ProductService from "../../services/ui/product-service";
import Price from "../../components/price/price";
import VariantSelect from "../../components/variant-select/variant-select";
import BuyButton from "../../components/buy-button/buy-button";
import ProductCard from "../../components/product-card/product-card";
import { Helmet } from "react-helmet";

export default function ProductPage() {

  const navigation = useNavigate();
  const { handle, variantSKU } = useParams();
  const [product, setProduct] = useState<ProductEntity>();
  const [variant, setVariant] = useState<VariantEntity>();
  const [selectedImage, setSelectedImage] = useState<string>();
  const [didYouMean, setDidYouMean] = useState<ProductEntity[]>();
  const [metafields, setMetafields] = useState<{ description: string }>();

  const defaultMetaDescription = `Staffordshire-based specialists in providing electric fireplaces across the west midlands, the UK and beyond. Get in contact today for more information.`;

  const setSelectedVariant = useCallback((product: ProductEntity, variantSKU: string | undefined) => {
    if (product === undefined) return;
    if (product.variants.length === 0) return;
    setProduct(product);
    var variantToSet = product.variants[0]
    if (variantSKU !== undefined) variantToSet = product.variants.find(v => v.sku === variantSKU) || product.variants[0];
    setVariant(variantToSet);
    setSelectedImage(variantToSet.featuredMedia);
  }, [])

  useEffect(() => {
    if (handle === undefined) return;
    ProductService.GetProduct(handle, {
      success: (_: ProductEntity) => {
        setProduct(_);
        setSelectedVariant(_, variantSKU)
        setSelectedImage(_.featuredMedia);
        setMetafields(JSON.parse(_.metafields));
      },
      error: (err: any) => {
        ProductService.SearchProducts(handle.split('-')[0], {
          success: function (_: ProductEntity[]): void {
            setDidYouMean(_);
          },
          error: function (err: any): void {
            navigation('/');
          }
        })
      }
    })
  }, [handle, navigation, setSelectedVariant, variantSKU])

  useEffect(() => {
    // change url without navigating
    if (variant === undefined) return;
    window.history.replaceState(null, '', `/product/${handle}/${variant.sku}`)
    setSelectedImage(variant.featuredMedia);
  }, [variant, handle])

  const Thumbnail = (media: string, alt: string) => {
    return <div onClick={() => setSelectedImage(media)} className="bg-white h-100 d-flex align-items-center justify-content-center interact border border-1 rounded-lg overflow-hidden p-2" style={{ minWidth: 100, maxWidth: 100 }}>
      <img loading={'lazy'} src={media} className="d-block w-100" alt={alt} />
    </div >
  }

  const DidYouMeanSection = () => {
    return <div className="container pt-5 h-100">
      <div className="row">
        <h4>We couldn't find what you were looking for...</h4>
        <h6>Did you mean?</h6>
      </div>
      <div className="row">
        {didYouMean !== undefined && didYouMean.length > 0 ? didYouMean.map((p: ProductEntity, i: number) => {
          return <div key={i} className="my-4 col-6 col-sm-6 col-md-4 col-lg-3">
            <ProductCard product={p} />
          </div>
        }) : <h4 className="w-100 text-center my-4">No Products.</h4>}
      </div>
    </div>
  }

  return <div className="bg-white h-100 flex-fill">
    {product === undefined || variant === undefined ? (<>
      {didYouMean === undefined ? <></> : DidYouMeanSection()}
    </>) :
      <>
        <Helmet>
          <meta charSet="utf-8" />
          <title>{product.title}</title>
          <meta name="og:title" content={product.title} />
          <meta name="description" content={metafields === undefined || metafields === null ? defaultMetaDescription : metafields.description || defaultMetaDescription} />
          <meta name="og:description" content={metafields === undefined || metafields === null ? defaultMetaDescription : metafields.description || defaultMetaDescription} />
          <meta name="og:image" content={product.featuredMedia} />
          <meta name="og:url" content={`https://fireplaceshops.com/product/${product.handle}`} />
          <meta name="og:type" content={`product`} />

          <script type="application/ld+json">
            {`{
              "@context": "https://schema.org/",
              "@type": "Product",
              "name": "${product.title}",
              "image": [
                "${product.featuredMedia}"
              ],
              "description": "${product.description}",
              "sku": "${product.sku}",
              "brand": {
                "@type": "Brand",
                "name": "FLAMME"
              },
              "offers": {
                "@type": "Offer",
                "url": "https://fireplaceshops.com/product/${product.handle}",
                "priceCurrency": "GBP",
                "price": "${product.price}",
                "availability": "https://schema.org/InStock",
                "itemCondition": "https://schema.org/NewCondition"
              }
            }`}
          </script>
        </Helmet>

        <div className="container pt-4">
          <div className="row">
            <div className="col-12 col-md-6">
              <div>
                <img src={selectedImage || product.featuredMedia} className="d-block w-100" alt={product.title} />
              </div>
              <div className="d-flex align-items-stretch justify-content-start gap-1 overflow-auto pb-2 mt-2">
                {/* thumbnails */}
                {
                  product.variants.map((v, i) => {
                    if (v.featuredMedia === null || v.featuredMedia === '') return null;
                    return <div key={i}>
                      {Thumbnail(v.featuredMedia, v.title)}
                    </div>
                  })
                }
                {product.media.sort((a, b) => a.order - b.order).map((m, i) => <div key={i}>{Thumbnail(m.url, product.title)}</div>)}
              </div>
            </div>
            <div className="col-12 col-md-6 pt-4">
              <h2>{product.title}</h2>
              <p>{product.description}</p>
              <h2 className="mt-3 mb-4"><Price price={variant.price} currencyCode="GBP" /></h2>
              <VariantSelect productOptions={product.options} variants={product.variants} selectedVariant={variant} variantChange={(v: VariantEntity) => {
                setVariant(v);
              }} />
              <BuyButton product={product} variant={variant} />
            </div>
          </div >
        </div>

        <div className="container pt-4 position-relative">
          <div className="row pb-5">
            <div className="col-12">
              <div className="p-3">
                <h5 className="fw-bolder">Description</h5>
                <div className="product-description" dangerouslySetInnerHTML={{ __html: product.HTMLdescription }}></div>
              </div>
            </div>
          </div>
        </div>
      </>
    }
  </div>;
}


